(function(){
    /*! Copyright 2015 to eternity: Dash Agency | http://dashagency.com/ */


    /*------------------------------------*\
        Utilities and helper scripts
    \*------------------------------------*/
    (function(){
        //=require base/utilities.js
    })();




})();
